/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { FieldArray, Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Select from '@components/Formik/Select';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import AddAnotherButton from '../components/ButtonAddAnother';
import DeleteButton from '../components/ButtonDelete';
import PrimaryRadio from '../components/PrimaryRadio';
import { areasOfExpertiseValidationSchema } from '../validationSchema';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import {
  EditProfileSharingPermission,
  FormExpertiseItem,
  mapAreasofExpertiseDataToFormValues,
  mapFormValuesToUpdateAreasOfExpertiseInput,
} from '@domui-domain/profile';
import Individual from '@domui-utils/workflow/individual';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

interface Props extends RouteComponentProps {
  individualId: string;
}
export type EditExpertisesSharingPermissions = {
  expertise: EditProfileSharingPermission;
};

export type EditExpertisesFormValues = {
  areasOfExpertise: FormExpertiseItem[];
  sharingPermissionsExtended: EditExpertisesSharingPermissions;
};

const AreasOfExpertiseEdit: React.FC<Props> = ({ individualId }) => {
  const [AreasOfExpertiseData, setAreasOfExpertiseData] = useState<any>();
  const [dominoID, setDominoID] = useState<any>();
  const [ExpertiseArea, setExpertiseArea] = useState<any>();
  const [ExpertiseLevel, setExpertiseLevel] = useState<any>();
  const [sharingPermissionData, setSharingPermissionData] = useState([]);

  // const [ setSharingPermissionData] = useState([]);

  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const { user } = useAppConfig();

  useEffect(() => {
    async function getAreasofExpertise() {
      if (user?.nfKey) {
        const { nfKey }: any = user;
        const wfService = new Individual();
        const domId = await wfService.getDominoId(nfKey);
        setDominoID(domId);
        try {
          const response = await wfService.getAreasofExpertise(domId);
          if (response?.errorDetails?.responseData?.status === 404) {
            const response = {
              AreasOfExpertise: [],
            };
            setAreasOfExpertiseData(response);
          } else {
            setAreasOfExpertiseData(response);
          }
          const metaWfService = new Metadata();
          const ResponseExpertiseAreas = await metaWfService.getExpertiseAreas();
          setExpertiseArea(ResponseExpertiseAreas);

          const ResponseExpertiseLevels = await metaWfService.getExpertiseLevels();
          setExpertiseLevel(ResponseExpertiseLevels);

          const responseSharingPermission = await metaWfService.getSharingPermissionData();
          setSharingPermissionData(responseSharingPermission);
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
          const response = {
            AreasOfExpertise: [],
          };
          setAreasOfExpertiseData(response);
        }
      }
    }
    getAreasofExpertise();
  }, [user]);

  // useEffect(() => {
  //   async function fetchSharingPermissionData() {
  //     const wfService = new Metadata();
  //     const response = await wfService.getSharingPermissionData();
  //     setSharingPermissionData(response);
  //   }
  //   fetchSharingPermissionData();
  // }, []);

  function findMissingAndCombineValues(
    arr1: any,
    arr2: any,
    key: any,
    actionKey: any,
    actionValue: any
  ) {
    const arr2values = arr2.map((item: { [x: string]: any }) => item[key]);
    const deletedObject = arr1.filter(
      (item: { [x: string]: any }) => !arr2values.includes(item[key])
    );
    if (deletedObject?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      deletedObject.forEach((item: any) => {
        // eslint-disable-next-line no-param-reassign
        item[actionKey] = actionValue.delete;
      });
    }
    const addUpdateMutationValues = arr2.map((value: any) => {
      if (
        value.id === undefined ||
        value.id === 'undefined' ||
        value.id === null ||
        value.id === ''
      ) {
        // eslint-disable-next-line no-param-reassign
        delete value.id;
        return { ...value, [actionKey]: actionValue.add };
      }
      if (value?.id) {
        return { ...value, [actionKey]: actionValue.update };
      }
      return value;
    });
    return [...addUpdateMutationValues, ...deletedObject];
  }

  const handleFormSubmit = async (values: EditExpertisesFormValues) => {
    const mutationValues = mapFormValuesToUpdateAreasOfExpertiseInput(values);

    const newKey = 'action';
    const action = {
      add: 'Add',
      update: 'Update',
      delete: 'Delete',
    };

    const data1 = AreasOfExpertiseData.areasOfExpertise;
    const data2 = mutationValues.primaryExpertise;
    const findMissingAndCombineObjects = findMissingAndCombineValues(
      data1,
      data2,
      'id',
      newKey,
      action
    );
    const sharingPermissionId: any =
      values?.sharingPermissionsExtended?.expertise?.id;
    const wfService = new Individual();
    await wfService.updateAreasOfExpertise(
      dominoID,
      sharingPermissionId,
      findMissingAndCombineObjects
    );

    localizedNavigate(`/domui/profile/${individualId}`);
    addSuccess(
      t('edit-Areas-Of-Expertise.form.success', 'Update successful.'),
      { id: 'form.success' }
    );
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}`);
  };

  if (!AreasOfExpertiseData) {
    return <Loading />;
  }

  const ExpertiseAreaOptions =
    ExpertiseArea.map((item: any) => ({
      value: item.id,
      label: item.value,
    })) || [];

  const ExpertiseLevelOptions =
    ExpertiseLevel.map((item: any) => ({
      value: item.id,
      label: item.value,
    })) || [];

  const formValues = mapAreasofExpertiseDataToFormValues(AreasOfExpertiseData);

  if (formValues.areasOfExpertise.length === 0) {
    formValues.areasOfExpertise = [
      {
        areaId: '',
        area: '',
        level: '',
        levelId: '',
        isPrimary: true,
        id: '',
      },
    ];
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        onSubmit={handleFormSubmit}
        validationSchema={areasOfExpertiseValidationSchema(t)}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const selectPrimary = (selectedIndex: number) => {
            setFieldValue(
              'expertises',
              values.areasOfExpertise.map(
                (expertise: FormExpertiseItem, index: number) =>
                  index === selectedIndex
                    ? { ...expertise, isPrimary: true }
                    : { ...expertise, isPrimary: false }
              )
            );
          };
          const multipleExpertises = values.areasOfExpertise.length > 1;
          const makePrimary = values.areasOfExpertise.length < 1;

          return (
            <>
              <LinkPrevious
                path={`/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>
                {t('edit-areas-of-expertise.title', 'Edit Areas Of Expertise')}
              </Title>
              <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                  {formValues.sharingPermissionsExtended && (
                    <SharingPermissionSelect
                      selectName="sharingPermissionsExtended.expertise.id"
                      sharingPermissionData={sharingPermissionData}
                    />
                  )}
                </div>
                <div className="desktop:flex-2 desktop:mr-24">
                  <Form className="max-w-lg">
                    <FieldArray
                      name="expertises"
                      render={({ push, remove }) => (
                        <>
                          {values.areasOfExpertise.map((expertise, i) => (
                            <div
                              key={`${expertise}`}
                              className="pb-8 mb-8 border-b border-gray-300 border-dotted"
                            >
                              <Select
                                name={`expertises[${i}].areaId`}
                                label={`${t(
                                  'edit-area-of-expertise.form.area.label',
                                  'Area of Expertise'
                                )}<span class="sr-only"> ${i + 1}</span>`}
                                options={ExpertiseAreaOptions}
                                required
                              />
                              <Select
                                name={`expertises[${i}].levelId`}
                                label={`${t(
                                  'edit-area-of-expertise.form.level.label',
                                  'Level of Expertise'
                                )}<span class="sr-only"> ${i + 1}</span>`}
                                options={ExpertiseLevelOptions}
                                required
                              />
                              <div className="mt-8 flex">
                                <PrimaryRadio
                                  checked={expertise.isPrimary}
                                  id={`primary-radio-${i}`}
                                  name="primaryOccupation"
                                  onClick={() => selectPrimary(i)}
                                  label={`<span class="sr-only">select expertise-${i}</span> ${t(
                                    'edit-professional-experience.primary-label',
                                    'Primary'
                                  )}`}
                                />
                                {((multipleExpertises &&
                                  !expertise.isPrimary) ||
                                  !multipleExpertises) && (
                                  <DeleteButton onClick={() => remove(i)} />
                                )}
                              </div>
                            </div>
                          ))}
                          <AddAnotherButton
                            label={t(
                              'edit-areas-of-expertise.add-label',
                              'Add another area of expertise'
                            )}
                            onClick={() =>
                              push({
                                areaId: '',
                                levelId: '',
                                isPrimary: makePrimary,
                              })
                            }
                          />
                          <Button
                            full
                            className="mt-16 mb-6"
                            clickHandler={() => {
                              handleSubmit();
                            }}
                          >
                            {t(
                              'edit-professional-experience.form.submit-label',
                              'Save Changes'
                            )}
                          </Button>
                          <Button
                            type="button"
                            full
                            text
                            clickHandler={handleCancel}
                          >
                            {t(
                              'edit-professional-experience.form.cancel-label',
                              'Cancel'
                            )}
                          </Button>
                        </>
                      )}
                    />
                  </Form>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default AreasOfExpertiseEdit;
