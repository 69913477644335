import { selectProfessionValues } from './mapProfessionalExperienceDataToFormValues';

import { isNotNullish } from '@typings/util';

import { EditProfessionsFormValues } from '../types';

// Filter out unwanted object properties, like __typename.
export const mapFormValuesToUpdateProfessionalExperienceInput = (
  values: EditProfessionsFormValues
) => {
  return {
    professions: values.professionalExperiences
      .map(selectProfessionValues)
      .filter(isNotNullish),
    sharingPermissionsExtended: values.sharingPermissionsExtended
      ? { profession: values.sharingPermissionsExtended.profession.id }
      : null,
  };
};
