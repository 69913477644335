// Libs
import React from 'react';

import classNames from 'classnames';

// Components
import Icon from '@domui-components/Icon';
import Link from '@domui-components/Link';

// Hooks
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useMessaging } from '@hooks/messaging';

const LinkMessage: React.FC<{ addresseeNFKey?: string | null }> = ({
  addresseeNFKey,
}) => {
  const { t, i18n } = useTranslation();
  const senderNFKey = useAppConfig()?.user?.nfKey;

  const {
    data: {
      forbidden: messagingForbidden,
      from: senderDrupalId,
      to: addresseeDrupalId,
    } = {},
    error: messagingError,
    loading: messagingLoading,
  } = useMessaging(i18n.language, senderNFKey, addresseeNFKey);

  const isMessagingAvailable =
    senderNFKey &&
    addresseeNFKey &&
    senderNFKey !== addresseeNFKey &&
    !messagingError &&
    !messagingLoading &&
    !messagingForbidden;

  return isMessagingAvailable ? (
    <Link
      to={`/user/${senderDrupalId}/privatemsg/new/${addresseeDrupalId}`}
      className={classNames(
        'message-link flex items-center inline-block ml-2 link-styles-off h5',
        'alternate text-bright-blue-600'
      )}
    >
      <Icon name="ui/message" size="12" className="mr-2" />
      <span>
        {t('user-profile.message-link-label', 'Message')}
        <span className="sr-only">
          {t('view-profile.personal-details.send-message', 'Send message')}
        </span>
      </span>
    </Link>
  ) : null;
};

export default LinkMessage;
