/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';

import { useFormikContext } from 'formik';

// Hooks
import Loading from '@components/Loading';

import Select from '../Select';

// Components

// Types
import { getSortedCountries } from '@domui-utils/getSortedCountries';

// import { Country } from '@typings/graphql';

// Utils

const generateOptions = (countries: any[]) =>
  countries.map(c => ({ label: c.countryName, value: c.countryName }));

const CountrySelect: React.FC<any> = ({ ...props }) => {
  // useErrorHandling(error?.message, !!error, 'fetch-countries.error');

  const { setFieldValue } = useFormikContext();

  const handleChange = (value: string) => {
    setFieldValue(props.name, value);
    setFieldValue(props.stateName, '');
  };

  if (!props.countryData) {
    return <Loading inline small />;
  }

  return (
    <Select
      onChange={handleChange}
      searchable
      options={generateOptions(getSortedCountries(props.countryData))}
      {...props}
    />
  );
};

export default CountrySelect;
