import React from 'react';

import moment from 'moment';

import { constructFullName } from '@utils/constructFullName';

import { Avatar } from '@domui-components/Avatar';
import Link from '@domui-components/Link';
import LinkEdit from '@domui-components/LinkEdit';
import LinkMessage from '@domui-components/LinkMessage';
import Title from '@domui-components/Title';

import { useTranslation } from '@external/react-i18next';

type PersonalDetailsProps = {
  firstName: string;
  lastName: string;
  middleName: string;
  localizedName: string;
  riIndividualId: string;
  nfKey: string;
  photoUri: string;
  suffix: string;
  prefix: string;
  gender: string;
  dateOfBirth: string;
  youthFlag: boolean;
  individualId: string | undefined;
  hasEditPermission: boolean;
};

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  firstName,
  lastName,
  middleName,
  localizedName,
  photoUri,
  riIndividualId,
  individualId,
  nfKey,
  suffix,
  prefix,
  gender,
  dateOfBirth,
  youthFlag,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });

  return (
    <div className="desktop:flex desktop:items-center border-b-4 border-dark-blue-400 pb-4 desktop:pb-10 mb-6">
      <div className="flex justify-center mb-4 desktop:mr-4">
        <Avatar
          alt={firstName || lastName || ''}
          size="big"
          photoUri={photoUri || undefined}
        />
      </div>
      <div className="w-full">
        <div className="flex items-baseline">
          <Title className="mr-auto mb-3">{fullName}</Title>
          {hasEditPermission && (
            <LinkEdit
              path={`/domui/profile/${individualId}/edit-personal`}
              component={t(
                'view-profile.personal-details.title',
                'Personal details'
              )}
            />
          )}
        </div>
        <div className="flex items-baseline">
          <div className="mr-auto">
            <Link to="#roles">
              {t('view-profile.personal-details.show-roles', 'Show roles')}
            </Link>

            {(gender || dateOfBirth) && (
              <p className="mb-0">
                {gender && gender !== 'NotGiven' && `${gender}, `}
                {!youthFlag && dateOfBirth
                  ? moment(dateOfBirth).format('DD MMMM YYYY')
                  : ''}
              </p>
            )}
            {(riIndividualId || localizedName) && (
              <p className="mb-0">
                {localizedName && (
                  <span className="font-bold">
                    {localizedName}
                    {riIndividualId && `, `}
                  </span>
                )}
                {riIndividualId && `ID: ${riIndividualId}`}
              </p>
            )}
          </div>
          <LinkMessage addresseeNFKey={nfKey} />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
