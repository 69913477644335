/* eslint-disable @typescript-eslint/no-explicit-any */
interface Email {
  address: string;
  id: string;
  isPrimary: boolean;
  nfKey: string;
  type: string;
}
interface Phone {
  id: string;
  type: string;
  number: string;
  countryId: string;
  countryName: string;
  extension: string | number;
  isPrimary: boolean;
  isFax: boolean;
  nfKey: string;
}

interface Address {
  id: string;
  nfKey: string;
  type: string;
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  countryId: string;
  countryName: null;
  city: string;
  stateId: string;
  stateName: string;
  internationalProvince: string;
  postalCode: string;
  isPrimary: boolean;
  hasStates: boolean;
}

interface phoneEmailAddress {
  email: Email[];
  phone: Phone[];
  address: Address[];
}
export type ContactInformationFormValues = {
  emails: Array<Email>;
  addresses: Array<Address>;
  phones: Array<Phone>;
};

export default (input: any): any => {
  let emails: any = [];
  let addresses: any = [];
  let phones: any = [];
  const { address, email, phone }: phoneEmailAddress = input;

  const primaryEmail = email.find((email: Email) => email.isPrimary);
  let otherEmails: any = email.find((email: Email) => !email.isPrimary);
  otherEmails = [otherEmails];

  const primaryPhone = phone.find((phone: Phone) => phone.isPrimary);
  let otherPhones: any = phone.find((phone: Phone) => !phone.isPrimary);
  otherPhones = [otherPhones];

  const primaryAddress = address.find((address: Address) => address.isPrimary);
  let otherAddresses: any = address.find(
    (address: Address) => !address.isPrimary
  );
  otherAddresses = [otherAddresses];

  if (primaryEmail) {
    emails = [{ ...primaryEmail, isPrimary: true, isSignin: true }];
  }

  if (otherEmails?.length) {
    emails = [
      ...emails,
      ...otherEmails.map((a: Email) => ({ ...a, isPrimary: false })),
    ];
  }
  const updatedEmails = email.map((item: any) => {
    return {
      ...item,
    };
  });
  emails = updatedEmails;

  if (primaryAddress) {
    addresses = [{ ...primaryAddress, isPrimary: true, hasStates: false }];
  }

  if (otherAddresses?.length) {
    addresses = [
      ...addresses,
      ...otherAddresses.map((a: Address) => ({
        ...a,
        isPrimary: false,
        hasStates: false,
      })),
    ];
  }
  const updatedAddress = address.map((item: any) => {
    const tempState = item.stateName;
    return {
      ...item,
      state: tempState,
      country: item.countryName,
    };
  });
  addresses = updatedAddress;

  if (primaryPhone) {
    phones = [{ ...primaryPhone, isPrimary: true }];
  }

  if (otherPhones?.length) {
    phones = [
      ...phones,
      ...otherPhones.map((a: Email) => ({ ...a, isPrimary: false })),
    ];
  }
  const updatedPhones = phone.map((item: any) => {
    const tempCountry = item.countryName;
    return {
      ...item,
      country: tempCountry,
    };
  });
  phones = updatedPhones;
  const sharingPermissions = {
    address: {
      id: input.sharingPermissions.address,
    },
    email: {
      id: input.sharingPermissions.email,
    },
    phone: {
      id: input.sharingPermissions.phone,
    },
  };

  return {
    addresses,
    phones,
    emails,
    sharingPermissionsExtended: sharingPermissions || null,
  };
};
