/* eslint-disable no-nested-ternary */
import * as React from 'react';

import Icon from '../Icon';

export const Avatar: React.FC<{
  photoUri?: string;
  unassigned?: boolean;
  unassignedIcon?: string;
  alt?: string;
  className?: string;
  size?: string;
}> = ({
  photoUri,
  unassigned,
  unassignedIcon,
  className = '',
  alt = '',
  size,
}) => {
  let sizeClasses;
  switch (size) {
    case 'medium':
      sizeClasses = 'h-30 w-30';
      break;
    case 'big':
      sizeClasses = 'h-30 w-30 desktop:h-45 desktop:w-45';
      break;
    case 'small':
      sizeClasses = 'h-6 w-6 desktop:h-6 desktop:w-6';
      break;
    default:
      sizeClasses = 'h-12 w-12 desktop:h-16 desktop:w-16';
  }

  return (
    <div
      className={`${sizeClasses} mr-4 rounded-full ${className}`}
      data-cy="avatar"
    >
      {unassigned ? (
        <Icon
          name={unassignedIcon || 'users/avatar-unassigned'}
          size="100%"
          color="gold-600"
        />
      ) : photoUri ? (
        <img
          className={`${sizeClasses} border-2 border-gray-300-60 rounded-full object-cover`}
          src={photoUri}
          alt={alt}
        />
      ) : (
        <Icon name="users/avatar-default" size="100%" />
      )}
    </div>
  );
};
