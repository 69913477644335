/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { useField } from 'formik';

import Loading from '@components/Loading';

import Select, { SelectProps } from '../Select';

import { getCountryCode } from '@utils/getCountryCode';

import FlagLabel from '@domui-components/FlagLabel';
import { getSortedCountries } from '@domui-utils/getSortedCountries';
import Metadata from 'src/domui/utils/workflow/metadata';

import { OptionTypeBase } from '@external/react-select';

import { Country } from '@typings/graphql';

const generateOptions = (countries: Country[]) =>
  countries.map((c: any) => ({
    label: <FlagLabel country={c} />,
    value: c.countryName,
  }));

type Props = Omit<SelectProps, 'options'>;

const CountryFlagSelect: React.FC<Props> = props => {
  const { name, isCountryFlagRequired } = props;
  const [countryList, setCountryList] = useState([]);
  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryList(response?.countries);
    }
    fetchCountryData();
  }, []);
  const [field, , helpers] = useField(name);

  const [highlightCountryFlagField, setHighlightCountryFlagField] = useState(
    isCountryFlagRequired
  );

  useEffect(() => {
    setHighlightCountryFlagField(isCountryFlagRequired);
  }, [isCountryFlagRequired]);

  const handleChange = (countryName: string) => {
    const country: any = countryList.find(
      (country: any) => country.countryName === countryName
    );
    // Country code is not the extension.
    const extension = ``;

    if (country) {
      const countryData = {
        type: field.value?.type,
        country: country.countryName,
        extension: extension || null,
        countryId: country.id,
        number: field.value?.number || '',
        isPrimary: field.value?.isPrimary,
      };

      helpers.setValue(countryData);
      setHighlightCountryFlagField(false);
    }
  };

  if (!countryList) {
    return <Loading inline small />;
  }

  const filterOption = (option: OptionTypeBase, input: string) => {
    if (!input) return true;

    const { value, label } = option;

    const strippedInput = input.replaceAll(/[^a-zA-Z0-9]/g, '');

    const countryId = getCountryCode(label?.props?.country?.shortCode);
    if (label === input) {
      return true;
    }

    if (
      typeof countryId === 'object' &&
      countryId.indexOf(String(strippedInput)) > -1
    ) {
      return true;
    }

    if (
      typeof countryId === 'number' &&
      String(countryId) === String(strippedInput)
    ) {
      return true;
    }

    const textRegx = new RegExp(String(value), 'gi');
    return textRegx.test(input);
  };

  return (
    <Select
      {...props}
      name={`${field.name}.country`}
      searchable
      onChange={countryName => handleChange(countryName)}
      filterOption={filterOption}
      options={generateOptions(getSortedCountries(countryList))}
      isCountryFlagRequired={highlightCountryFlagField}
      showErrorMessage={highlightCountryFlagField}
    />
  );
};

export default CountryFlagSelect;
