// Libs
import React from 'react';

// Components
import TextField from '@components/Formik/TextField';

// Hooks
import { getEmailFieldName } from '../utils';

import { useTranslation } from '@external/react-i18next';

// Utils

interface EmailFieldProps {
  index: number;
  address: string;
  user: string;
}

const EmailField: React.FC<EmailFieldProps> = ({ index, address, user }) => {
  const { t } = useTranslation();
  if (user === address) {
    return (
      <div>
        <p className="h5 text-gray-400">
          {t('edit-contact-information.email.form.address-label', 'Email')}*
        </p>
        <p className="text-gray-400">{address}</p>
        <p className="text-gray-500 text-sm my-2 max-w-sm">
          {t(
            'edit-contact-information.email.form.signin-mail-text',
            'This email is associated with your account and is used for account communication. You can edit this email under Account Settings.'
          )}
        </p>
      </div>
    );
  }
  return (
    <div>
      <TextField
        name={getEmailFieldName(index, 'address')}
        label={t('edit-contact-information.email.form.address-label', 'Email')}
        required
      />
    </div>
  );
};

export default EmailField;
