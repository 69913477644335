// Libs
import React from 'react';

// Components
import { getPhoneFieldName } from '../utils';

import PhoneInput from '@domui-components/Formik/PhoneInput';
import PhoneTypeSelect from '@domui-presenters/web/pages/Profile/Edit/ContactInformation/PhoneForm/PhoneTypeSelect';

// Utils

// Hooks
import { useTranslation } from '@external/react-i18next';

interface PhoneFieldProps {
  index: number;
}

const PhoneField: React.FC<PhoneFieldProps> = ({ index }) => {
  const { t } = useTranslation();

  const phoneInputLabels = {
    phoneCode: t('form.address.phone-country-label', 'Phone Extension'),
    phoneExtension: t('form.address.phone-extension', 'Extension'),
    phoneNumber: t(
      'edit-contact-information.phone.form.number-label',
      'Phone Number'
    ),
  };

  return (
    <div>
      <PhoneTypeSelect
        name={getPhoneFieldName(index, 'type')}
        label={t('edit-contact-information.phone.form.type-label', 'Type')}
        required
      />
      <PhoneInput
        suffix={getPhoneFieldName(index, '')}
        labels={phoneInputLabels}
        required
      />
    </div>
  );
};

export default PhoneField;
