import { EditExpertisesFormValues } from 'src/domui/presenters/web/pages/Profile/Edit/AreasOfExpertise';

import { ExpertiseInput } from '@typings/operations';

import { FormExpertiseItem } from '../types';

const selectExpertiseValues = (
  expertise: FormExpertiseItem | undefined
): ExpertiseInput | null => {
  if (expertise === undefined) {
    return null;
  }
  const { areaId, levelId } = expertise;
  return {
    areaId,
    levelId,
  };
};

export const mapFormValuesToUpdateAreasOfExpertiseInput = (
  values: EditExpertisesFormValues
) => {
  return {
    primaryExpertise: selectExpertiseValues(
      values.expertises.find(expertise => expertise.isPrimary)
    ),
    otherExpertise: values.expertises
      .filter(expertise => !expertise.isPrimary)
      .map(selectExpertiseValues),
    sharingPermissionsExtended: {
      expertise: values.sharingPermissionsExtended.expertise.id,
    },
  };
};
