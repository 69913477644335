import React from 'react';

import ContactInformationAddress from './ContactInformationAddress';
import ContactInformationEmail from './ContactInformationEmail';
import ContactInformationPhone from './ContactInformationPhone';

import LinkEdit from '@domui-components/LinkEdit';

import { useTranslation } from '@external/react-i18next';

enum EmailType {
  Business = 'Business',
  Personal = 'Personal',
}

enum PhoneType {
  Business = 'Business',
  Home = 'Home',
  Mobile = 'Mobile',
}

enum AddressType {
  Business = 'Business',
  Home = 'Home',
  HostCountry = 'HostCountry',
  Localized = 'Localized',
  Project = 'Project',
}

type ContactInfoProps = {
  emails?:
    | Array<{
        type: EmailType;
        address: string;
        isPrimary: boolean;
      }>
    | null
    | undefined;
  phones?:
    | Array<{
        country: string;
        countryId: string;
        isPrimary: boolean;
        extension?: string | null | undefined;
        number: string;
        type?: PhoneType | null | undefined;
      }>
    | null
    | undefined;
  addresses?:
    | Array<{
        type: AddressType;
        isPrimary: boolean;
        lineOne?: string | null | undefined;
        lineTwo?: string | null | undefined;
        lineThree?: string | null | undefined;
        country?: string | null | undefined;
        countryId: string;
        city?: string | null | undefined;
        stateId?: string | null | undefined;
        state?: string | null | undefined;
        postalCode?: string | null | undefined;
      }>
    | null
    | undefined;
  individualId: string | undefined;
  hasEditPermission: boolean;
};

const ContactInformation: React.FC<ContactInfoProps> = ({
  emails,
  phones,
  addresses,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-between items-start">
        <h2>{t('contact-information.title', 'Contact Information')}</h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/domui/profile/${individualId}/edit-contact`}
            component={t('contact-information.title', 'Contact Information')}
          />
        )}
      </div>
      {!!emails?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.email-primary', 'Primary Email')}
          </h3>
          {emails
            .filter(email => email.isPrimary)
            .map(email => (
              <div key={email.address}>
                <ContactInformationEmail email={email} />
              </div>
            ))}
        </div>
      )}
      {!!emails?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.alternate-email', 'Alternate Email')}
          </h3>
          {emails
            .filter(email => !email.isPrimary)
            .map(email => (
              <div key={email.address}>
                <ContactInformationEmail className="mt-2" email={email} />
              </div>
            ))}
        </div>
      )}
      {!!phones?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.phone-primary', 'Primary Phone')}
          </h3>
          {phones
            .filter(phone => phone.isPrimary)
            .map(phone => (
              <ContactInformationPhone phone={phone} key={phone.number} />
            ))}
        </div>
      )}
      {!!phones?.length && (
        <div className="mt-10">
          <h3 className="h5">
            {t('contact-information.alternate-phone', 'Alternate Phone')}
          </h3>
          {phones
            .filter(phone => !phone.isPrimary)
            .map(phone => (
              <ContactInformationPhone
                className="mt-2"
                phone={phone}
                key={phone.number}
              />
            ))}
        </div>
      )}
      {!!addresses?.length && (
        <>
          {addresses
            .filter(address => address.isPrimary)
            .map(address => {
              return (
                <div
                  className="mt-10"
                  key={`${address.lineOne} ${address.type}`}
                >
                  <ContactInformationAddress
                    address={address}
                    label={t(
                      'contact-information.address-primary',
                      'Primary Address'
                    )}
                  />
                </div>
              );
            })}
        </>
      )}
      {!!addresses?.length && (
        <>
          {addresses
            .filter(address => !address.isPrimary)
            .map(address => {
              return (
                <div
                  className="mt-10"
                  key={`${address.lineOne} ${address.type}`}
                >
                  <ContactInformationAddress
                    address={address}
                    label={t(
                      'contact-information.address-alternate',
                      'Alternate Address'
                    )}
                  />
                </div>
              );
            })}
        </>
      )}
    </>
  );
};

export default ContactInformation;
