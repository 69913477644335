import React from 'react';

import { getAddress } from '@utils/getAddress';

import { Address, BaseAddress } from '@typings/graphql';

interface Props {
  address: Address;
  label: string;
  className?: string;
}

const ContactInformationAddress: React.FC<Props> = ({
  address,
  className,
  label,
}) => {
  return (
    <div className={className}>
      <h3 className="h5">
        {label}
        <span> ({address.type})</span>
      </h3>
      {getAddress('', address as BaseAddress).map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={item + index} className="m-0">
          {item}
        </p>
      ))}
    </div>
  );
};

export default ContactInformationAddress;
