// Libs
import React from 'react';

import Divider from '@domui-components/Divider';
import LinkEdit from '@domui-components/LinkEdit';

import { useTranslation } from '@external/react-i18next';

type Expertise = {
  expertises?:
    | Array<{
        id: string | null | undefined;
        areaId: string | null | undefined;
        area: string | null | undefined;
        levelId: string | null | undefined;
        level: string | null | undefined;
        isPrimary: boolean;
      }>
    | null
    | undefined;
  hasEditPermission: boolean;
  individualId: string | undefined;
};

const AreasOfExpertise: React.FC<Expertise> = ({
  expertises,
  hasEditPermission,
  individualId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>
          {t('view-profile.areas-of-expertise.title', 'Areas of Expertise')}
        </h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/domui/${individualId}/edit-areas-of-expertise`}
            component={t(
              'view-profile.areas-of-expertise.title',
              'Areas of Expertise'
            )}
          />
        )}
      </div>
      {!!expertises?.length && (
        <div className="max-w-4xl mb-8">
          <ul>
            {expertises.map(area => (
              <div key={area.levelId}>
                <h3 className="h5">{area.level}</h3>
                <li className="mb-0 last:mb-4">
                  {area.area}
                  {area.isPrimary &&
                    ` (${t(
                      'view-profile.areas-of-expertise.primary',
                      'Primary'
                    )})`}
                </li>
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default AreasOfExpertise;
