/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Formik } from 'formik';
// import { trim } from 'lodash';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import {
  formatDateOfBirth,
  getDateOfBirth,
} from '@components/Formik/DateOfBirth/utils';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import PersonalDetailsForm from './PersonalDetailsForm';
import ProfilePhoto from './ProfilePhoto/ProfilePhoto';
import SharingPermissionMessage from './SharingPermissionMessage';
import { personalDetailsValidationSchema } from '../validationSchema';

import { PersonalDetailsFormValues } from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useDeletePhoto, useUploadPhoto } from '@repositories/profile/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { useAppConfig } from '@domui-hooks/appConfig';
import Individual from '@domui-utils/workflow/individual';

import { useTranslation } from '@external/react-i18next';
import { useProfilePhoto } from '@hooks/profile-photo';

import { Gender } from '@typings/operations';

interface Props extends RouteComponentProps {
  individualId: string;
}

const PersonalDetailsEdit: React.FC<Props> = ({ individualId }) => {
  const { user } = useAppConfig();
  const { t } = useTranslation();
  const { addSuccess, addError } = useNotifications();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(false);
  const [profileData, setProfileData] = useState<any>({});

  useEffect(() => {
    async function geProfileDInfo() {
      if (user?.nfKey) {
        const { nfKey }: any = user;
        const wfService = new Individual();
        const domId = await wfService.getDominoId(nfKey);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const profileInfo = await wfService.getProfileInfo(domId);
        if (!profileInfo?.id) {
          setError({
            message: 'Error while retrive the profile info',
          });
        }
        setProfileData({
          personalDetails: {
            ...profileInfo,
            dateOfBirth: profileInfo.dob,
            sharingPermissionsExtended: {},
            editPermissions: {
              photo: false,
            },
          },
        });

        setLoading(false);
      }
    }

    geProfileDInfo();
  }, [user]);

  useErrorHandling(error?.message, !!error, 'fetch.error');
  const canEditPhoto = !!profileData?.personalDetails?.editPermissions?.photo;

  // const [
  //   // updatePersonalDetails,
  //   { loading: updating },
  // ] = useUpdatePersonalDetails();

  const [uploadPhoto, { loading: uploading }] = useUploadPhoto();

  const [deletePhoto, { loading: deleting }] = useDeletePhoto();

  const { updateProfilePhoto } = useProfilePhoto();

  const handleFormSubmit = async (values: PersonalDetailsFormValues) => {
    const {
      suffix,
      dayOfBirth,
      monthOfBirth,
      yearOfBirth,
      // sharingPermissionsExtended,
      firstName,
      lastName,
      // localizedName,
      gender,
      // genderDescription,
      ...updatedValues
    } = values;

    try {
      if (user?.nfKey) {
        const { dateOfBirth, yearOfBirth: yob } = getDateOfBirth(
          dayOfBirth,
          monthOfBirth,
          yearOfBirth
        );
        setLoading(true);
        const { nfKey }: any = user;
        const wfService = new Individual();
        const domId = await wfService.getDominoId(nfKey);
        await wfService.updateProfileInfo(domId, {
          ...updatedValues,
          firstName: firstName || '',
          lastName: lastName || '',
          dateOfBirth,
          yearOfBirth: `${yob}`,
          suffix: suffix || '',
          gender: !gender ? Gender.NotGiven : gender,
        });
        setLoading(false);
      }

      // await updatePersonalDetails({
      //   variables: {
      //     id: individualId,
      //     ...updatedValues,
      //     suffix: suffix || '',
      //     firstName: firstName || '',
      //     lastName: lastName || '',
      //     localizedName: localizedName || '',
      //     gender: !gender ? Gender.NotGiven : gender,
      //     genderDescription: !genderDescription
      //       ? null
      //       : trim(genderDescription),
      //     ...getDateOfBirth(dayOfBirth, monthOfBirth, yearOfBirth),
      //     // The last level (id) needs to be removed.
      //     sharingPermissionsExtended: sharingPermissionsExtended
      //       ? {
      //           photo: sharingPermissionsExtended.photo.id,
      //         }
      //       : null,
      //   },
      // });

      if (canEditPhoto) {
        // Strictly check for false. Null is the default and tells that no change
        // was made.
        if (values.photoUpload === false) {
          await deletePhoto({
            variables: { individualId },
          });
        } else if (values.photoUpload !== null) {
          // https://stackoverflow.com/a/52311051/580371
          const getBase64 = (file: File): Promise<string> => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                let encoded = reader.result
                  ?.toString()
                  .replace(/^data:(.*,)?/, '');
                if (encoded && encoded.length % 4 > 0) {
                  encoded += '='.repeat(4 - (encoded.length % 4));
                }
                resolve(encoded || '');
              };
              reader.onerror = error => reject(error);
            });
          };

          await uploadPhoto({
            variables: {
              individualId,
              fileName: values.photoUpload.name,
              fileSize: values.photoUpload.size.toString(),
              fileMimeType: values.photoUpload.type,
              fileContentsBase64Encoded: await getBase64(values.photoUpload),
            },
          });

          updateProfilePhoto(values.photoUpload);
        }
      }

      localizedNavigate(`/domui/profile/${individualId}`);
      addSuccess(
        t('edit-personal-details.form.success', 'Update successful.'),
        { id: 'form.success' }
      );
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  if (loading || uploading || deleting || !profileData?.personalDetails) {
    return <Loading />;
  }

  const initialValues = {
    ...profileData.personalDetails,
    ...formatDateOfBirth(
      profileData.personalDetails.dateOfBirth,
      profileData.personalDetails.yearOfBirth
    ),
    photoUpload: null,
  };

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={personalDetailsValidationSchema(t)}
      >
        {({ values, isValid, handleSubmit }) => (
          <>
            <LinkPrevious
              path={`/profile/${individualId}`}
              label={t('edit-profile.back-link', 'Profile')}
            />
            <Title>
              {t('edit-personal-details.title', 'Edit Personal Details')}
            </Title>
            {canEditPhoto && (
              <>
                <ProfilePhoto
                  photoUri={initialValues.photoUri}
                  showSharingPermissionSelect={
                    !!initialValues.sharingPermissionsExtended
                  }
                />
                <h2>
                  {t(
                    'edit-personal-details.subheader-personal-information',
                    'Personal Information'
                  )}
                </h2>
              </>
            )}
            <div className="desktop:flex desktop:flex-row-reverse">
              <SharingPermissionMessage />
              <PersonalDetailsForm values={values} />
            </div>
            <Divider />
            <div className="max-w-lg mb-20 mt-10">
              <Button
                full
                clickHandler={() => {
                  if (!isValid)
                    addError(
                      t(
                        'edit-personal-details.form.error-msg',
                        'Please correct the invalid fields'
                      ),
                      { id: 'form.error' }
                    );
                  handleSubmit();
                }}
              >
                {t('edit-personal-details.form.submit-label', 'Save Changes')}
              </Button>
              <Button className="mt-6" full text clickHandler={handleCancel}>
                {t('edit-personal-details.form.cancel-label', 'Cancel')}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </OneColumn>
  );
};

export default PersonalDetailsEdit;
