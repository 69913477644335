/* eslint-disable spaced-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import AreasOfExpertise from './AreasOfExpertise';
import Background from './Background';
import ContactInformation from './ContactInformation';
import PersonalDetails from './PersonalDetails';
import ProfessionalExperience from './ProfessionalExperience';
// import Resume from './Resume';
// import RotaryPrograms from './RotaryPrograms';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import Icon from '@domui-components/Icon';
import { OneColumn } from '@domui-components/Layouts/OneColumn';
import Loading from '@domui-components/Loading';
import { useAppConfig } from '@domui-hooks/appConfig';
import Individual from '@domui-utils/workflow/individual';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const ProfileView: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const { addError } = useNotifications();

  const [profileData, setProfileData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getProfileInfo() {
      if (user?.nfKey) {
        const { nfKey }: any = user;
        const wfService = new Individual();
        try {
          const domId = await wfService.getDominoId(nfKey);
          try {
            const res = await wfService.getDominoIndividual(domId);
            setProfileData(res);
            setLoading(false);
          } catch (error) {
            addError((error as Error).message, { id: 'form.error' });
            setLoading(false);
          }
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
          setLoading(false);
        }
      }
    }
    getProfileInfo();
  }, [user]);

  if (!profileData || loading) {
    return <Loading />;
  }

  const {
    firstName,
    lastName,
    middleName,
    suffix,
    prefix,
    gender,
    photoUri,
    localizedName,
    riIndividualId,
    youthFlag,
    dateOfBirth,
    nfKey,
    emails,
    phones,
    addresses,
    aboutMe,
    expertises,
    professions,
  } = profileData;

  const handleBackToPreviousPage = () => {
    const previousPathUrl = window.sessionStorage.getItem('previousPagePath');

    if (!previousPathUrl) return window.history.back();

    window.sessionStorage.removeItem('previousPagePath');
    return localizedNavigate(previousPathUrl);
  };

  return (
    <OneColumn className="mb-20">
      <span
        onClick={() => handleBackToPreviousPage()}
        className="inline-flex items-center font-bold text-bright-blue-600 link-styles text-xs leading-xs-heading mb-8"
      >
        <Icon name="arrows/left" className="mr-2" size="12" />
        {t('view-profile.back-link', 'Back to previous page')}
      </span>
      <PersonalDetails
        {...{
          firstName,
          lastName,
          middleName,
          suffix,
          prefix,
          gender,
          photoUri,
          localizedName,
          riIndividualId,
          dateOfBirth,
          youthFlag,
          nfKey,
          individualId,
          hasEditPermission: true,
        }}
      />
      <ContactInformation
        {...{
          emails,
          phones,
          addresses,
          individualId,
          hasEditPermission: true,
        }}
      />
      <Background
        {...{
          aboutMe,
          individualId,
          hasEditPermission: true,
        }}
      />
      {/* <Resume
        {...{
          // resumeRoles,
          // resumeRecognitions,
          // resumeSponsorships,
          hasEditPermission: true,
          individualId,
        }}
      /> */}
      {/* <RotaryPrograms
        {...{
          programs,
          individualId,
          hasEditPermission: true,
        }}
      /> */}
      <ProfessionalExperience
        {...{
          individualId,
          professions,
          hasEditPermission: true,
        }}
      />
      <AreasOfExpertise
        {...{
          individualId,
          areasOfExpertise: expertises,
          hasEditPermission: true,
        }}
      />
    </OneColumn>
  );
};

export default ProfileView;
