/* eslint-disable @typescript-eslint/no-explicit-any */
type SelectInput = {
  expertises: any;
  map(
    arg0: (
      a: any
    ) => {
      id: Maybe<string>;
      areaId: Maybe<string>;
      area: Maybe<string>;
      levelId: Maybe<string>;
      level: Maybe<string>;
      isPrimary: boolean;
    }
  ): unknown;
  sharingPermission?:
    | {
        expertise: {
          levelId: string;
        };
      }
    | null
    | undefined;
};

export type Maybe<T> = T | null;
type Expertise = {
  id?: Maybe<string>;
  areaId?: Maybe<string>;
  area?: Maybe<string>;
  levelId?: Maybe<string>;
  level?: Maybe<string>;
  isPrimary: Maybe<boolean>;
};

// Filter out unwanted object properties, like __typename.
export const selectAreasOfExpertiseValues = (
  expertise: Expertise | undefined
): Expertise | null => {
  if (expertise === undefined) {
    return null;
  }
  const { areaId, area, levelId, level, id, isPrimary } = expertise;
  return {
    areaId,
    area,
    levelId,
    level,
    id,
    isPrimary,
  };
};

export const mapAreasofExpertiseDataToFormValues = (input: SelectInput) => {
  let areasOfExpertise: any[] = [];

  if (input?.expertises.length > 0) {
    areasOfExpertise = [
      ...areasOfExpertise,

      ...input.expertises.map((a: any) => ({
        ...selectAreasOfExpertiseValues(a),
        // eslint-disable-next-line no-unneeded-ternary
        isPrimary: a.isPrimary ? true : false,
      })),
    ];
  }

  const sharingPermissionsExtended = input.sharingPermission
    ? {
        expertise: { id: input.sharingPermission.expertise.levelId },
      }
    : {
        expertise: { id: '' },
      };

  return { areasOfExpertise, sharingPermissionsExtended };
};
