// Libs
import React from 'react';

// Components
import Divider from '@domui-components/Divider';
import LinkEdit from '@domui-components/LinkEdit';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { FetchProfileQuery } from '@typings/operations';

type Props = Pick<FetchProfileQuery['individual'], 'aboutMe'> & {
  individualId: string | undefined;
  hasEditPermission: boolean;
};

const Background: React.FC<Props> = ({
  aboutMe,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>{t('view-profile.background.title', 'Background')}</h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/domui/profile/${individualId}/edit-background`}
            component={t('view-profile.background.title', 'Background')}
          />
        )}
      </div>
      {aboutMe && (
        <>
          <h3 className="h5">
            {t('view-profile.background.about-me', 'About me')}
          </h3>
          <p className="max-w-4xl whitespace-pre-wrap">{aboutMe}</p>
        </>
      )}
    </>
  );
};

export default Background;
