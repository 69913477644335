// Libs
import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
// Utils
import { navigate } from 'gatsby';

// Components
import Loading from '@domui-components/Loading';
// Hooks
import { useAppConfig } from '@domui-hooks/appConfig';

const MyProfile: React.FC<RouteComponentProps> = () => {
  const { user } = useAppConfig();
  useEffect(() => {
    navigate(`/profile/${user?.individualId}`, { replace: true });
  }, [user]);
  return <Loading />;
};

export default MyProfile;
