/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import TextField from '@components/Formik/TextField';
import Icon from '@components/Icon';

import CountrySelect from '../Select/CountrySelect';
import StateSelect from '../Select/StateSelect';

import { zipCodeIsRequired } from '@utils/validation';

import { useTranslation } from '@external/react-i18next';

interface AddressFieldsProps {
  countryData: any;
  countryId?: string;
  mainLabel?: string;
  name: {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
    countryId?: string;
    city?: string;
    stateId?: string;
    state?: string;
    postalCode?: string;
    internationalProvince?: string;
    hasStates?: string;
  };
  placeholder?: {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
  };
  requiredFields?: {
    lineOne?: boolean;
    lineTwo?: boolean;
    lineThree?: boolean;
    country?: boolean;
    city?: boolean;
    state?: boolean;
    postalCode?: boolean;
  };
  countrylabel?: string;
  citylabel?: string;
  stateLabel?: string;
  postalcode?: string;
  displayClearButton?: boolean;
  isClearButtonDisabled?: boolean;
  clearButtonStyle?: string;
}

const AddressFields: React.FC<AddressFieldsProps> = ({
  countryData,
  countryId,
  mainLabel,
  countrylabel,
  citylabel,
  stateLabel,
  postalcode,
  name: {
    lineOne = 'address.lineOne',
    lineTwo = 'address.lineTwo',
    lineThree = 'address.lineThree',
    city = 'address.city',
    stateId = 'address.stateId',
    postalCode = 'address.postalCode',
    internationalProvince = 'address.internationalProvince',
    hasStates = 'address.hasStates',
  },
  name,
  placeholder,
  requiredFields,
  displayClearButton,
  isClearButtonDisabled,
  clearButtonStyle,
}) => {
  const { t } = useTranslation();

  const {
    lineOne: requiredLineOne = true,
    lineTwo: requiredLineTwo = false,
    lineThree: requiredLineThree = false,
    country: requiredCountry = true,
    city: requiredCity = true,
    state: requiredState = true,
    postalCode: requiredPostal = false,
  } = requiredFields || {};
  const { handleChange, setFieldValue } = useFormikContext();

  const clearFields = () => {
    setFieldValue(lineOne, '');
    setFieldValue(lineTwo, '');
    setFieldValue(lineThree, '');
    setFieldValue(stateId, '');
    setFieldValue(postalCode, '');
    setFieldValue(city, '');
    setFieldValue(hasStates, '');
    setFieldValue(name.state ? name.state : 'address.state', '');
    setFieldValue(name.countryId ? name.countryId : 'address.countryId', '');
  };

  const countryValue: any = countryData.find(
    (c: any) => c.countryName === countryId
  );

  return (
    <>
      {displayClearButton && (
        <div className="float-right">
          <button
            type="button"
            disabled={isClearButtonDisabled}
            onClick={clearFields}
            className={classNames(
              `edit-link mr-2 flex items-center inline-block h5 alternate ${clearButtonStyle}`,
              {
                'text-gray-400': isClearButtonDisabled,
                'text-bright-blue-600': !isClearButtonDisabled,
              }
            )}
          >
            <Icon name="ui/trashcan" size="12" className="mr-2" />
            <span>{t('club-editcontact.clear', 'Clear')}</span>
          </button>
        </div>
      )}
      <TextField
        name={lineOne}
        label={mainLabel || t('form.address.address-label', 'Address')}
        required={requiredLineOne}
        placeholder={placeholder?.lineOne}
      />
      <TextField
        name={lineTwo}
        label={t('form.address.address-label-line-two', 'Address line two')}
        labelHidden
        required={requiredLineTwo}
        placeholder={placeholder?.lineTwo}
      />
      <TextField
        name={lineThree}
        label={t('form.address.address-label-line-three', 'Address line three')}
        labelHidden
        required={requiredLineThree}
        placeholder={placeholder?.lineThree}
      />
      <CountrySelect
        name={name.countryId || 'address.countryId'}
        countryData={countryData}
        stateName={stateId}
        onChange={(selectedOption: any) => {
          setFieldValue(stateId, '');
          handleChange(name.countryId || 'address.countryId')(
            selectedOption.valueOf()
          );
        }}
        label={
          countrylabel || t('form.address.country-label', 'Country/Region')
        }
        required={requiredCountry}
        clearable
      />
      <TextField
        name={city}
        label={citylabel || t('form.address.city-label', 'City')}
        required={requiredCity}
      />
      {countryId && (
        <StateSelect
          countryId={countryId}
          countryValue={countryValue?.id}
          name={stateId}
          label={
            stateLabel ||
            t('form.address.state-label', 'State/Province/Territory')
          }
          textStateName={internationalProvince}
          hasStatesName={hasStates}
          required={requiredState}
        />
      )}
      <TextField
        name={postalCode}
        label={postalcode || t('form.address.postalCode-label', 'Postal code')}
        required={requiredPostal || zipCodeIsRequired(countryId || '')}
      />
    </>
  );
};

export default AddressFields;
