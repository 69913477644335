import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextArea from '@components/Formik/TextArea';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { backgroundValidationSchema } from '../validationSchema';

import { MAX_BACKGROUND_CHAR } from '@domain/profile';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import { useAppConfig } from '@domui-hooks/appConfig';
import Individual from '@domui-utils/workflow/individual';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const BackgroundEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();

  const [dominoID, setDominoID] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [aboutme, setAboutme] = useState<any>();
  const [sharingPermissionData, setSharingPermissionData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useAppConfig();

  useEffect(() => {
    async function getDominoIndividual() {
      if (user?.nfKey) {
        const { nfKey } = user;
        const wfService = new Individual();
        const domId = await wfService.getDominoId(nfKey);
        setDominoID(domId);

        const resp = await wfService.getDominoIndividual(domId);

        setAboutme({
          aboutMe: resp.aboutMe,
          sharingPermissionsExtended: {
            aboutMe: {
              id: resp?.sharingPermission?.aboutMe?.levelId,
            },
          },
        });

        try {
          const wfService = new Metadata();
          const response = await wfService.getSharingPermissionData();
          setSharingPermissionData(response);
        } catch (error) {
          addError((error as Error).message, { id: 'form.error' });
        }
        setLoading(false);
      }
    }

    getDominoIndividual();
  }, [user]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormSubmit = async (values: any) => {
    setLoading(true);
    const wfService = new Individual();
    try {
      await wfService.updateEditBackground({
        id: dominoID,
        aboutMe: values?.aboutMe,
        sharingPermissionId: values?.sharingPermissionsExtended?.aboutMe?.id,
      });
      localizedNavigate(`/domui/profile/${individualId}`);
      addSuccess(t('edit-background.form.success', 'Update successful.'), {
        id: 'form.success',
      });
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
      setLoading(false);
    }
    setLoading(false);
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}`);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={aboutme}
        onSubmit={handleFormSubmit}
        validationSchema={backgroundValidationSchema(t)}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <>
              <LinkPrevious
                path={`/domui/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>{t('edit-background.title', 'Edit Background')}</Title>
              <Form>
                <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0">
                    <SharingPermissionSelect
                      selectName="sharingPermissionsExtended.aboutMe.id"
                      sharingPermissionData={sharingPermissionData}
                    />
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <TextArea
                      name="aboutMe"
                      label={t(
                        'edit-background.form.about-me-label',
                        'About Me'
                      )}
                      characters={MAX_BACKGROUND_CHAR}
                    />
                  </div>
                </div>
                <div className="max-w-lg">
                  <Button
                    full
                    className="mt-16 mb-6"
                    clickHandler={() => {
                      if (!isValid)
                        addError(
                          t(
                            'edit-background.form.error-msg',
                            'Please correct the invalid fields'
                          ),
                          { id: 'form.error' }
                        );
                      handleSubmit();
                    }}
                  >
                    {t('edit-background.form.submit-label', 'Save Changes')}
                  </Button>
                  <Button type="button" full text clickHandler={handleCancel}>
                    {t('edit-background.form.cancel-label', 'Cancel')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default BackgroundEdit;
