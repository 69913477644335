/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import AddressForm from './AddressForm';
import EmailForm from './EmailForm';
import PhoneForm from './PhoneForm';
import { processContactInfoForDomino } from './utils';
import { contactInformationValidationSchema } from '../validationSchema';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useNotifications } from '@use-cases/notifications';
import { getStorageID } from '@use-cases/storage';

import { FEATURE_DOMINO_METADATA, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionsAlerts from '@domui-components/Formik/Select/SharingPermissionsAlerts';
import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import {
  FormItem,
  mapContactInformationDataToFormValues,
} from '@domui-domain/profile';
import { useAppConfig } from '@domui-hooks/appConfig';
import Individual from '@domui-utils/workflow/individual';
import Metadata from 'src/domui/utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

import { EmailType } from '@typings/graphql';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const ContactInformationEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const [dominoId, setDominoId] = useState<any>();
  const [contactInfo, setContactInfo] = useState<any>();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [updateContactInfo, setUpdateContactInfo] = useState<any>();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const { user } = useAppConfig();
  const [countryData, setCountryData] = useState([]);
  const [sharingPermissionData, setSharingPermissionData] = useState([]);

  // const { nfKey }: any = user;

  useEffect(() => {
    async function updateContactInfoData() {
      if (updateContactInfo) {
        setIsLoading(true);
        const wfService = new Individual();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await wfService.updateContactInfo(
          dominoId,
          updateContactInfo
        );
        setIsLoading(false);
        localizedNavigate(`/domui/profile/${individualId}`);
        addSuccess(
          t('edit-personal-details.form.success', 'Update successful.'),
          {
            id: 'form.success',
          }
        );
        // const sharingPermissionWfService = new Metadata();

        // const response = await sharingPermissionWfService.getSharingPermissionData();
        // setSharingPermission(response);
      }
    }

    updateContactInfoData();
  }, [updateContactInfo]);

  useEffect(() => {
    async function getUserInfo() {
      if (user?.nfKey) {
        const { nfKey }: any = user;
        const wfService = new Individual();
        const domId = await wfService.getDominoId(nfKey);
        setDominoId(domId);
        const res = await wfService.getContactInfo(domId);
        setContactInfo(res?.contactInfo);
      }
    }

    getUserInfo();
  }, [user]);

  // const { data, error } = useDISCountries();
  useEffect(() => {
    async function fetchCountryData() {
      const wfService = new Metadata();
      const response = await wfService.getCountryData();
      setCountryData(response?.countries);
    }
    fetchCountryData();
  }, []);

  useEffect(() => {
    async function fetchSharingPermissionData() {
      const wfService = new Metadata();

      const response = await wfService.getSharingPermissionData();
      setSharingPermissionData(response);
    }
    fetchSharingPermissionData();
  }, []);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const isDominoFlagEnabled = isEnabled(FEATURE_DOMINO_METADATA);
  if (!contactInfo) {
    return <Loading />;
  }
  if (loading) {
    return <Loading />;
  }

  const modalOnConfirmHandler = () => {
    localizedNavigate(`/profile/${individualId}`);
  };

  const formValues = mapContactInformationDataToFormValues(contactInfo);

  if (!formValues.emails.length) {
    formValues.emails.push({
      address: '',
      isPrimary: true,
      type: EmailType.Personal,
      id: '',
      nfKey: '',
    });
  }

  const handleFormSubmit = async (values: any) => {
    let updatedEmails: any = processContactInfoForDomino(
      formValues.emails,
      values.emails
    );
    const updatedAddress = processContactInfoForDomino(
      formValues.addresses,
      values.addresses
    );
    const updatedPhones = processContactInfoForDomino(
      formValues.phones,
      values.phones
    );
    const updatedSharingPermission = {
      address: values.sharingPermissionsExtended?.address?.id,
      email: values.sharingPermissionsExtended?.email?.id,
      phone: values.sharingPermissionsExtended?.phone?.id,
    };
    updatedEmails = updatedEmails.map((email: any) => ({
      ...email,
      type: email.type.toLowerCase(),
    }));

    const contactInfoPayLoad = {
      id: dominoId,
      contactInfo: {
        address: [...updatedAddress],
        email: [...updatedEmails],
        phone: [...updatedPhones],
        sharingPermissions: updatedSharingPermission,
      },
    };
    setUpdateContactInfo(contactInfoPayLoad);
    const userCacheId = `user-account-${individualId}`;
    const previousData = sessionStorage.getItem(
      getStorageID(userCacheId)
    ) as string;

    const prevData = JSON.parse(previousData);

    const newData = {
      getIndividual: {
        ...prevData?.getIndividual,
        email: [...updatedEmails],
        primaryPhone: [...updatedPhones],
        primaryAddress: [...updatedAddress],
      },
    };

    sessionStorage.setItem(getStorageID(userCacheId), JSON.stringify(newData));
  };

  const handleFormSubmitForDomino = async (values: any) => {
    const updatedEmails = processContactInfoForDomino(
      formValues.emails,
      values.emails
    );
    const updatedAddress = processContactInfoForDomino(
      formValues.addresses,
      values.addresses
    );
    const updatedPhones = processContactInfoForDomino(
      formValues.phones,
      values.phones
    );

    const contactInfo = {
      address: [...updatedAddress],
      email: [...updatedEmails],
      phone: [...updatedPhones],
      sharingPermissions: values.sharingPermissionsExtended,
    };
    setUpdateContactInfo(contactInfo);

    const userCacheId = `user-account-${individualId}`;
    const previousData = sessionStorage.getItem(
      getStorageID(userCacheId)
    ) as string;

    const prevData = JSON.parse(previousData);

    const newData = {
      getIndividual: {
        ...prevData?.getIndividual,
        email: [...updatedEmails],
        primaryPhone: [...updatedPhones],
        primaryAddress: [...updatedAddress],
      },
    };

    sessionStorage.setItem(getStorageID(userCacheId), JSON.stringify(newData));

    localizedNavigate(`/domui/profile/${individualId}`);
    addSuccess(t('edit-personal-details.form.success', 'Update successful.'), {
      id: 'form.success',
    });
  };

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        validationSchema={contactInformationValidationSchema(t)}
        onSubmit={
          isDominoFlagEnabled ? handleFormSubmitForDomino : handleFormSubmit
        }
      >
        {({ values, isValid, setFieldValue }) => {
          const selectPrimary = (fieldName: any) => (selectedIndex: number) => {
            setFieldValue(
              fieldName,
              // @ts-ignore
              values[fieldName].map((item: FormItem, index: number) => {
                return index === selectedIndex
                  ? { ...item, isPrimary: true }
                  : { ...item, isPrimary: false };
              })
            );
          };

          return (
            <>
              <LinkPrevious
                path=""
                showModal={modalBackHandler}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>
                {t(
                  'edit-contact-information.title',
                  'Edit Contact Information'
                )}
              </Title>
              <Form>
                <h2 className="mt-10 desktop:mt-24">
                  {t('edit-personal-details.subheader-email', 'Email')}
                </h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                    {formValues?.sharingPermissionsExtended && (
                      <>
                        <SharingPermissionSelect
                          selectName="sharingPermissionsExtended.email.id"
                          isClubOfficer={!!contactInfo?.isClubOfficer}
                          sharingPermissionData={sharingPermissionData}
                        />
                        <SharingPermissionsAlerts
                          selectedSharingPermission={
                            values.sharingPermissionsExtended?.email
                          }
                          isClubOrDistrictOfficer={
                            !!contactInfo?.isClubOrDistrictOfficer
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <EmailForm
                      emails={values.emails}
                      user={user}
                      selectPrimary={selectPrimary('emails')}
                    />
                  </div>
                </div>
                <Divider size={8} />
                <h2>{t('edit-personal-details.subheader-phone', 'Phone')}</h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                    {formValues.sharingPermissionsExtended && (
                      <SharingPermissionSelect
                        selectName="sharingPermissionsExtended.phone.id"
                        sharingPermissionData={sharingPermissionData}
                      />
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <PhoneForm
                      phones={values.phones}
                      selectPrimary={selectPrimary('phones')}
                    />
                  </div>
                </div>
                <Divider size={8} />
                <h2>
                  {t('edit-personal-details.subheader-address', 'Address')}
                </h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg">
                    {formValues.sharingPermissionsExtended && (
                      <SharingPermissionSelect
                        selectName="sharingPermissionsExtended.address.id"
                        sharingPermissionData={sharingPermissionData}
                      />
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <AddressForm
                      addresses={values.addresses}
                      countryData={countryData}
                      selectPrimary={selectPrimary('addresses')}
                    />
                  </div>
                </div>
                <div className="max-w-lg">
                  <Button
                    full
                    className="mt-16 mb-6"
                    clickHandler={() => {
                      if (!isValid)
                        addError(
                          t(
                            'edit-personal-details.form.error-msg',
                            'Please correct the invalid fields'
                          ),
                          { id: 'form.error' }
                        );
                    }}
                  >
                    {t(
                      'edit-personal-details.form.submit-label',
                      'Save Changes'
                    )}
                  </Button>
                  <Button
                    full
                    text
                    type="button"
                    clickHandler={modalBackHandler}
                  >
                    {t('edit-personal-details.form.cancel-label', 'Cancel')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </OneColumn>
  );
};

export default ContactInformationEdit;
