/* eslint-disable @typescript-eslint/no-explicit-any */
// Libs
import React from 'react';

import { FieldArray } from 'formik';

// Components
import AddressField from './AddressField';
import AddAnotherButton from '../../components/ButtonAddAnother';
import DeleteButton from '../../components/ButtonDelete';
import PrimaryRadio from '../../components/PrimaryRadio';
import { getNewAddress } from '../utils';

// Utils
import { ContactInformationFormValues, FormAddressItem } from '@domain/profile';

import { useTranslation } from '@external/react-i18next';

export interface Props {
  addresses: ContactInformationFormValues['addresses'];
  selectPrimary: (selectedIndex: number) => void;
  countryData: any;
}

const AddressForm: React.FC<any> = ({
  addresses,
  selectPrimary,
  countryData,
}) => {
  const { t } = useTranslation();
  const totalAddresses = addresses.length;
  const onlyOneAddress = totalAddresses === 1;
  return (
    <div className="max-w-lg">
      <FieldArray name="addresses">
        {({ push, remove }) => (
          <>
            {addresses.map((address: FormAddressItem, index: number) => {
              const uniqueKey = `${index}-${address.stateId}-${address.countryId}`;
              return (
                <div className="mt-8 first:mt-0 mb-8" key={uniqueKey}>
                  <AddressField
                    index={index}
                    key={uniqueKey}
                    countryData={countryData}
                    {...address}
                  />
                  <div className="flex justify-between">
                    <PrimaryRadio
                      checked={address.isPrimary}
                      id={`primary-address-radio-${index}`}
                      onClick={() => selectPrimary(index)}
                      label={t(
                        'edit-contact-information.address.primary-label',
                        'Primary Address for RI communication'
                      )}
                    />
                    {!address.isPrimary && !onlyOneAddress ? (
                      <DeleteButton onClick={() => remove(index)} />
                    ) : null}
                  </div>
                  {!onlyOneAddress && index !== totalAddresses - 1 && (
                    <hr
                      data-testid="address-seperator"
                      className="border-t-0 border-b border-gray-300 w-full mt-6 desktop:mt-10 mb-8"
                    />
                  )}
                </div>
              );
            })}
            <AddAnotherButton
              label={t(
                'edit-contact-information.address.add-label',
                'Add another address'
              )}
              onClick={() =>
                push({ ...getNewAddress(), isPrimary: addresses.length === 0 })
              }
              color="black"
            />
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default AddressForm;
