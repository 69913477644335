// Libs
import React from 'react';

// Components
import Select, { SelectProps } from '@components/Formik/Select';

// Hooks
import { useTranslation } from '@external/react-i18next';

// Types
import { AddressType } from '@typings/operations';

type Props = Omit<SelectProps, 'options'>;

const AddressTypeSelect: React.FC<Props> = props => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('edit-profile.form.addresstype.business', 'Business'),
      value: AddressType.Business,
    },
    {
      label: t('edit-profile.form.addresstype.home', 'Home'),
      value: AddressType.Home,
    },
    {
      label: t('edit-profile.form.addresstype.localized', 'Localized'),
      value: AddressType.Localized,
    },
  ];

  return <Select options={options} {...props} />;
};

export default AddressTypeSelect;
