// Libs
import React from 'react';

import Experience from './Experience';

// Components
import Divider from '@domui-components/Divider';
import LinkEdit from '@domui-components/LinkEdit';

// Hooks
import { useTranslation } from '@external/react-i18next';

type Props = {
  professions?:
    | Array<{
        occupation?: string | null | undefined;
        employer?: string | null | undefined;
        position?: string | null | undefined;
        isPrimary: boolean;
      }>
    | null
    | undefined;
  individualId: string | undefined;
  hasEditPermission: boolean;
};

const ProfessionalExperience: React.FC<Props> = ({
  professions,
  individualId,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider desktopOnly />
      <div className="flex justify-between items-start">
        <h2>
          {t(
            'view-profile.professional-experience.title',
            'Professional Experience'
          )}
        </h2>
        {hasEditPermission && (
          <LinkEdit
            path={`/domui/profile/${individualId}/edit-professional-experience`}
            component={t(
              'view-profile.professional-experience.title',
              'Professional Experience'
            )}
          />
        )}
      </div>

      {!!professions?.length && (
        <div className="mb-8">
          <h3 className="h4 alternate">
            {t('view-profile.professional-experience.primary', 'Primary')}
          </h3>
          {professions
            .filter(profession => profession.isPrimary)
            .map((profession, i) => (
              <Experience
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-${profession.position}`}
                profession={profession}
              />
            ))}
        </div>
      )}
      {!!professions?.length && (
        <>
          <h3 className="h4 alternate">
            {t('view-profile.professional-experience.further', 'Further')}
          </h3>
          {professions
            .filter(profession => !profession.isPrimary)
            .map((profession, i) => (
              <Experience
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-${profession.position}`}
                profession={profession}
              />
            ))}
        </>
      )}
    </>
  );
};

export default ProfessionalExperience;
